import { createRouter, createWebHashHistory} from "vue-router"
import store from '@/store'
import { login, logout } from '@/utils/is4'
import defaultSettings from '@/settings'
import { getToken,getExpiredAt,removeToken,removeExpiredAt} from '@/utils/auth'
import {ElMessage} from 'element-plus'
  // 配置路由信息
  function getPageTitle(pageTitle) {
	return pageTitle
  }
  export const constantRoutes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/account/login.vue'),
		meta: {
			title: "登录"
		}
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/account/register.vue'),
		meta: {
			title: "注册"
		}
	},
  	{
		path: '/',
		name: 'layout',
		component: () => import('@/layout/index.vue'),
		children: [
			{
				path: '/home',
				name: 'home',
				component: () => import('@/views/home.vue'),
				meta: {
				  title: "首页",
				  path: "/home",
				  icon: "icon-shouye",
				  closable:true
				}
			},
			// {
			// 	path: '/userBehavior/monitoringRecords',
			// 	name: 'userBehaviorMonitoringRecords',
			// 	component: () => import('@/views/userBehavior/monitoringRecords.vue'),
			// 	meta: {
			// 		title: "报告列表",
			// 		path: "/userBehavior/monitoringRecords",
			// 		icon: "icon-xitongjiance",
			// 	  	closable:true
			// 	}
			// },
			// 小柔报告
			// {
			// 	path: '/userBehavior/sleepHandAccount',
			// 	name: 'sleepHandAccount',
			// 	component: () => import('@/views/userBehavior/sleepHandAccount.vue'),
			// 	meta: {
			// 		title: "睡眠报告",
			// 		path: "/userBehavior/sleepHandAccount",
			// 	  	closable:true
			// 	}
			// },
			// 医疗端报告
			// {
			// 	path: '/userBehavior/sleepReport',
			// 	name: 'sleepReport',
			// 	component: () => import('@/views/userBehavior/sleepReport.vue'),
			// 	meta: {
			// 		title: "睡眠报告",
			// 		path: "/userBehavior/sleepReport",
			// 	  	closable:true
			// 	}
			// },
			{
				path: '/device/InfoManagement',
				name: 'deviceInfoManagement',
				component: () => import('@/views/device/InfoManagement.vue'),
				meta: {
					title: "设备列表",
					path: "/device/InfoManagement",
					icon: "icon-jianceshebeiguanli",
				  	closable:true
				}
			},
			{
				path: '/analysis/plug-in',
				name: 'plugIn',
				component: () => import('@/views/analysis/plug-in.vue'),
				meta: {
					title: "插件功能管理",
					path: "/analysis/plug-in",
				  	closable:true
				}
			},
			{
				path: '/analysis/data-analysis',
				name: 'dataList',
				component: () => import('@/views/analysis/data-analysis.vue'),
				meta: {
					title: "数据分析",
					path: "/analysis/data-analysis",
				  	closable:true
				}
			},
			{
				path: '/userBehavior/medicalSideReportRecords',
				name: 'medicalSideReportRecords',
				component: () => import('@/views/userBehavior/medicalSideReportRecords.vue'),
				meta: {
					title: "医疗记录列表",
					path: "/userBehavior/medicalSideReportRecords",
					icon: "icon-xitongjiance",
				  	closable:true
				}
			},
			{
				path: '/userBehavior/medicalSleepReport',
				name: 'medicalSleepReport',
				component: () => import('@/views/userBehavior/medicalSleepReport.vue'),
				meta: {
					title: "医疗报告",
					path: "/userBehavior/medicalSleepReport",
					icon: "icon-xitongjiance",
				  	closable:true
				}
			},
		]
	},
	
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes:constantRoutes,
	scrollBehavior(to, from, savedPosition) {
		// return {
		// 	x: 0,
		// 	y: 0
		// }
		//使用浏览器的回退或者前进时，重新返回时保留页面滚动位置，跳转页面的话，不触发。
		if(savedPosition){
	  		return savedPosition;
	    }else{
	  		return {to:0};
		}
	}
})
// 设置缓存视图
function setCachedViews() {
	// 获取视图缓存
	const cachedViews = store.getters.tabsList.map(t => t.name)
	// console.log('cachedViews',cachedViews);
	// 设置存储视图缓存名
	store.commit('tabsView/set_cached_view', cachedViews)
}
// 登录
function toLogin(to, next) {
	// 自动登录判断
	// next({ path: '/' })
  
	if (defaultSettings.is4) {
	  if (to.path === '/callback' || to.path === '/refresh' || to.path === '/register') {
		next()
	  } else {
		login()
	  }
	} else {
	  if (to.path === '/login' || to.path === '/register') {
		next()
	  } else {
		// next(`/login?redirect=${to.path}`)
		next(`/login`)
	  }
	}
  }

router.beforeEach(async(to, from, next) => {
	if(to.meta.title === '' || to.meta.title === undefined || to.meta.title === null){
	  document.title = '柔灵开放平台'
	}else{
	  document.title = getPageTitle(to.meta.title)
	}
	to.meta.keepAlive = false;
	// next()
	const token = getToken()
	setCachedViews()
	// next()
	if (token) {
		let date = new Date().getTime();
		let expiredAt = getExpiredAt()
		if (to.path === '/login' || to.path === '/register') {
		  toLogin(to, next)
		} else {
			if(getExpiredAt()){
				if (date > expiredAt) {
				  removeToken()
				  removeExpiredAt()
				  next({ name: "login" });
				  ElMessage.error('登录失效，请重新登录！')
				}else{
				  next()
				}
			}else{
				next()
			}
		}
	} else {
		toLogin(to, next)
	}
})
// 登出
export function toLogout() {
	store.dispatch('user/logout')
	// router.push('/login')
	if (defaultSettings.is4) {
		logout()
	} else {
		router.push('/login')
	}
}

export default router


/**
 * vue3的动态路由写法
 */
//  import {createRouter, createWebHashHistory} from 'vue-router'
//  import store from '../store'
//  import { getToken } from '@/utils/auth'
//  import defaultSettings from '@/settings'
//  import { login, logout } from '@/utils/is4'
// var routes = [
// 	{
// 	  path: '/login',
// 	  // name: 'Login',
// 		// component: () => import(`@/views/account/login`),
// 		component: () => import(`@/views/account/login`),
// 	  hidden: true,
// 	  meta: {
// 		title: '登录'
// 	  }
// 	},
// 	{
// 	  path: '/',
// 	  // component:(resolve) => require([`@/layout`], resolve),
// 	  component: () => import(`@/layout`),
// 	  children: [],
// 	  name: 'layout',
// 	  meta: {}
// 	},
// ]
// const router = createRouter({
// 	history: createWebHashHistory(process.env.BASE_URL),
// 	routes,
// 	scrollBehavior(to, from, savedPosition) {
// 		return {
// 			x: 0,
// 			y: 0
// 		}
// 	}
// })
// // 生成路由
// function generateRoutes(menus = []) {
// 	const routes = {
// 	    path: '/',
// 	    // component:(resolve) => require([`@/layout`], resolve),
// 	    component: () => import(`@/layout`),
// 		children: [],
// 		name: '',
// 		meta: {}
// 	}
// 	if(menus && menus.length > 0){
// 	  menus.forEach(m => {
// 		if (m.list && m.list.length > 0) {
// 		  m.list.forEach(element => {
// 			  const route = {
// 				name: element.menuPath,
// 				path: '/'+ element.menuPath,
// 				// closable:true,
// 				component: () => import(`@/views/${element.menuPath}`),
// 				meta: {
// 				  title: element.menuName,
// 				  icon: element.icon,
// 				  closable: null,
// 				  path: '/'+ element.menuPath,
// 				  closable:true
// 				}
// 			  }
// 			  routes.children.push(route)
// 		  });
// 		}
// 	  })
// 	}
  
// 	// 修复无首页时不显示404
// 	routes.children.push({
// 	  path: '',
// 	  hidden: true
// 	})
  
// 	routes.children.push({
// 		path: '/:catchAll(.*)',
// 		component: () => import(`@/views/error/404.vue`),
// 		hidden: true
// 	})
	
// 	return [routes]
//   }

// // 添加路由
// export function addRoutes(menus = []) {
// 	// 生成动态路由
// 	if (menus && menus.length > 0) {
// 		const dynamicRoutes = generateRoutes(menus)
// 		for (let x of dynamicRoutes) {//新方式解决警告
// 			// router.addRoute("index", x);
// 			router.addRoute(x);
// 		}
// 	}
// }
// addRoutes(store.getters.menus)



// function getPageTitle(pageTitle) {
// 	return pageTitle
//   }
// // 登出
// export function toLogout() {
// 	store.dispatch('user/logout')
// 	if (defaultSettings.is4) {
// 		logout()
// 	} else {
// 		router.push('/login')
// 	}
// }
// // 登录
// function toLogin(to, next) {
// 	// 自动登录判断
// 	// next({ path: '/' })
// 	if (defaultSettings.is4) {
// 	  if (to.path === '/callback' || to.path === '/refresh') {
// 		next()
// 	  } else {
// 		login()
// 	  }
// 	} else {
// 	  if (to.path === '/login') {
// 		next()
// 	  } else {
// 		next(`/login?redirect=${to.path}`)
// 	  }
// 	}
//   }

// // 设置缓存视图
// function setCachedViews() {
// 	// 获取视图缓存
// 	let sessionStorageTabs = sessionStorage.getItem('tabs')
// 	sessionStorageTabs = sessionStorageTabs ? JSON.parse(sessionStorageTabs) : []
// 	const cachedViews = sessionStorageTabs.map(t => t.name)
// 	// 设置存储视图缓存名
// 	store.commit('tabsView/set_cached_view', cachedViews)
//   }
  
//   let first = true
//   // 路由全局前置守卫
//   router.beforeEach(async(to, from, next) => {
// 	if(to.meta.title === '' || to.meta.title === undefined || to.meta.title === null){
// 	  document.title = '柔灵后台管理系统'
// 	}else{
// 	  document.title = getPageTitle(to.meta.title)
// 	}
// 	// 用于判断哪些需要被缓存哪些不需要
// 	if(to.path === '/userBehavior/monitoringRecords'){
// 	  to.meta.keepAlive = true;
// 	}else{
// 	  to.meta.keepAlive = false;
// 	}
// 	const token = getToken()
  
// 	setCachedViews()
  
// 	if (token) {
// 	  if (to.path === '/login') {
// 		toLogin(to, next)
// 	  } else {
// 		const hasPermission = store.getters.menus && store.getters.menus.length > 0
// 		if (hasPermission) {
// 		  next()
// 		} else {
// 		  // 仅执行一次
// 		  if (first) {
// 			first = false
// 			const res = await store.dispatch('user/getLoginInfo')
// 			if (res && res.success) {
// 			  if (!(res.data?.menuList?.length > 0)) {
// 				// this.$confirm('无权限，请重新登录！', '提示').then(() => {
// 				//   toLogin(to, next)
// 				// }).catch(() => {})
// 				toLogin(to, next)
// 				return
// 			  }
// 			  next({ ...to, replace: true })
// 			} else {
// 			  toLogin(to, next)
// 			}
// 		  }
// 		}
// 	  }
// 	} else {
// 	  toLogin(to, next)
// 	}
//   })
// export default router